<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
      :items="items"
      divider=">"
      ></v-breadcrumbs>
      <v-row class="mt-md-12 mb-md-10 ma-5" no-gutters>
        <v-col :cols="12" :sm="4" :md="4">
        <v-text-field
          v-model="search"
          class="ph-textbox"
          placeholder="Search"
          append-icon="mdi-magnify"
          hide-details
          outlined
        />
      </v-col>
      <v-spacer></v-spacer>
        <v-col :cols="12" :sm="4" :md="3">
          <v-btn
            x-large
            :class="[{'ml-0': $vuetify.breakpoint.smAndDown}, {'selected': selectedApproval === 'nonex'}]"
            elevation="0"
            style="margin-left: 60px"
            color="grey darken-1"
            @click="approvalBtn('nonex')"
            outlined
          >
            Nonex Adjustment
          </v-btn>
        </v-col>
        <v-col :cols="12" :sm="4" :md="3">
          <v-btn
            x-large
            :class="[{'ml-0': $vuetify.breakpoint.smAndDown}, {'selected': selectedApproval === 'wu'}]"
            style="margin-left: 60px"
            color="grey darken-1"
            @click="approvalBtn('wu')"
            outlined
          >
            WU Adjustment
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-md-12 mb-md-10 ma-5" no-gutters>
        <v-col :cols="12">
          <v-card elevation="1" class="ph-nonex-datatable-container">
            <v-data-table
              :search="search"
              :headers="headers"
              :items="gridItems"
              class="custom-table"
              :calculate-widths="true"
            >
              <template v-slot:[`item.remco_id`]="{ item }">
                {{ getRemcoName(item.remco_id) }}
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ formatDate(item.trx_date) }}
              </template>
              <template v-slot:[`item.currency_id`]="{ item }">
                {{ item.currency_id === 1 ? 'PHP' : 'USD' }}
              </template>
              <template v-slot:[`item.adj_category`]="{ item }">
                {{ item.adj_category.toUpperCase() }}
              </template>
              <template v-slot:[`item.principal_amount`]="{ item }">
                {{ formatAmount(item.principal_amount) }}
              </template>
              <template v-slot:[`item.service_charge`]="{ item }">
                {{ formatAmount(item.service_charge) }}
              </template>
              <template v-slot:[`item.dst_amount`]="{ item }">
                {{ formatAmount(item.dst) }}
              </template>
              <!-- <template v-slot:[`item.trx_date`]="{ item }">
                {{ formatDate(item.trx_date) }}
              </template>
              <template v-slot:[`item.amount`]="{ item }">
                {{ !isNaN(item.amount) ? formatAmount(item.amount) : 0 }}
              </template>
              <template v-slot:[`item.is_cash`]="{ item }">
                {{ item.is_cash === '1' ? 'Deliver/Pick-up by Cash' : 'Deposit to Account' }}
              </template> -->
              <template v-slot:[`item.status`]="{ item }">
                <span class="ph-nonex-status-container red--text" v-if="item.status === 1 || item.status === '1'">
                  <v-icon color="orange" class="mr-1" x-small>mdi-circle</v-icon>
                  Pending
                </span>
                <span class="ph-nonex-status-container green--text" v-if="item.status === 2 || item.status === '2'">
                  <v-icon color="green" class="mr-1" x-small>mdi-circle</v-icon>
                  Approved
                </span>
                <span class="ph-nonex-status-container red--text" v-if="item.status === 3 || item.status === '3'">
                  <v-icon color="red" class="mr-1" x-small>mdi-circle</v-icon>
                  Disapproved
                </span>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <!-- <v-tooltip right v-if="item.status === 1 || item.status === '1'" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" @click="displayAdjData(item)" color="primary" icon>
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>View Info</span>
                </v-tooltip> -->
                <v-tooltip right v-if="item.status === 1 || item.status === '1'" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" @click="displayAdjData(item, 'approve')" color="primary" icon>
                      <v-icon>mdi-thumb-up</v-icon>
                    </v-btn>
                  </template>
                  <span>Approve</span>
                </v-tooltip>
                <v-tooltip right v-if="item.status === 1 || item.status === '1'" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" v-if="item.status === 1 || item.status === '1'" @click="displayAdjData(item, 'disapprove')" color="primary" icon>
                      <v-icon>mdi-thumb-down</v-icon>
                    </v-btn>
                  </template>
                  <span>Disapprove</span>
                </v-tooltip>
                <!-- <v-btn v-if="item.status !== 1 && item.status !== '1'" disabled color="primary" icon>
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn> -->
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-speed-dial
      v-model="fab"
      bottom
      right
      fixed
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="primary"
          dark
          fab
        >
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <v-tooltip left content-class="ph-tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="newNonexAdjustmentEntry"
            color="#05ACE5"
            v-bind="attrs"
            v-on="on"
            small
            fab
          >
            <img src="@/assets/images/send-money-obo-icon.svg" width="18" />
          </v-btn>
        </template>
        <span>New Nonex Adjustment Request Form</span>
      </v-tooltip>
      <v-tooltip left content-class="ph-tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="newWUAdjustmentEntry"
            fab
            light
            small
            color="black"
            v-bind="attrs"
            v-on="on"
          >
            <img src="@/assets/images/wu-logo.svg" width="18" />
          </v-btn>
        </template>
        <span>New WU Adjustment Request Form</span>
      </v-tooltip>
    </v-speed-dial>
    </v-container>
  </div>
</template>
<script>
import numeral from 'numeral'
import { renderToast } from '@/utils'
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'

export default {
  name: 'NonexAdjustmentIndex',
  data () {
    return {
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Pera Remit',
          disabled: false,
          href: '/pera-remit'
        },
        {
          text: 'Nonex Adjustment',
          disabled: true,
          href: '#'
        }
      ],
      headers: [
        {
          text: 'Remittance Company',
          sortable: true,
          value: 'remco_id'
        },
        {
          text: 'Transaction Date',
          sortable: true,
          value: 'created_at'
        },
        {
          text: 'Control Number',
          sortable: true,
          value: 'control_number'
        },
        {
          text: 'Sender\'s Name',
          sortable: true,
          value: 'sender_name'
        },
        {
          text: 'Receiver\'s Name',
          sortable: true,
          value: 'receiver_name'
        },
        {
          text: 'Currency',
          sortable: true,
          value: 'currency_id'
        },
        {
          text: 'Amount',
          sortable: true,
          value: 'principal_amount'
        },
        {
          text: 'Service Charge',
          sortable: true,
          value: 'service_charge'
        },
        {
          text: 'DST',
          sortable: true,
          value: 'dst_amount'
        },
        {
          text: 'Type',
          sortable: true,
          value: 'adj_category'
        }
      ],
      fab: false,
      search: '',
      selectedApproval: 'nonex',
      isWu: false
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      adjRequestList: 'nonexAdjustment/adjRequestList',
      adjWURequestList: 'nonexAdjustment/adjWURequestList',
      collections: 'collections'
    }),
    // Populate WU
    gridItems () {
      if (this.isWu) {
        return this.adjWURequestList.data
      } else {
        return this.adjRequestList
      }
    }
  },
  async beforeMount () {
    await this.$store.dispatch('nonexAdjustment/getAdjRequestList', {
      trx_date: this.currUser.trx_date,
      location_id: this.currUser.location_id
    })
    // Call wu items
    await this.$store.dispatch('nonexAdjustment/getAdjWURequestList', {
      trx_date: this.currUser.trx_date,
      location_id: this.currUser.location_id
    })
  },
  methods: {
    renderToast,
    getRemcoName (id) {
      let remcoName = ''

      switch (id) {
        case 1:
          remcoName = 'iRemit'
          break
        case 2:
          remcoName = 'BPI'
          break
        case 7:
          remcoName = 'Transfast'
          break
        case 8:
          remcoName = 'Metrobank'
          break
        case 9:
          remcoName = 'Cebuana Lhuillier'
          break
        case 10:
          remcoName = 'USSC'
          break
        case 11:
          remcoName = 'Express Money'
          break
        case 12:
          remcoName = 'Ria'
          break
        case 13:
          remcoName = 'Landbank'
          break
        case 14:
          remcoName = 'USSC DOLE'
          break
        case 15:
          remcoName = 'USSC DOLE'
          break
        case 16:
          remcoName = 'InstantCash'
          break
        case 17:
          remcoName = 'JapanRemit'
          break
        case 19:
          remcoName = 'Cebuana International'
          break
        case 20:
          remcoName = 'Uniteller'
          break
        case 21:
          remcoName = 'Remitly'
          break
        case 22:
          remcoName = 'Ayannah'
          break
        case 24:
          remcoName = 'Intel Express'
          break
        case 26:
          remcoName = 'GCash Payout'
          break
        case 27:
          remcoName = 'Fastpay'
          break
        case 28:
          remcoName = 'Teleremit'
          break
        // hardoded case for WU
        case 'Western':
          remcoName = 'Western Union'
          break
        default:
          remcoName = 'Not Provided'
      }

      return remcoName
    },
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    formatDate (date) {
      return format(parseISO(date), 'yyyy-MM-dd HH:mm:ss')
    },
    newNonexAdjustmentEntry () {
      this.$router.push('/pera-remit/nonex-adjustment/form')
    },
    // additional for WU adjustments
    newWUAdjustmentEntry () {
      this.$router.push('/pera-remit/nonex-adjustment/wu-form')
    },
    // determine if wu button is selected
    approvalBtn (data) {
      if (data === 'wu') {
        this.isWu = true
      } else {
        this.isWu = false
      }
      this.selectedApproval = data
    }
  }
}
</script>
