import apigee from '@/api/apigee'

export default {
  getResetDate (payload) {
    return apigee.get(`/uspdata/v1/usp-maintenance/get-location-reset?location_id=${payload.location_id}&action_type=${payload.action_type}`)
  },
  resetDate (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/location-reset-date', payload)
  }
}
