import { endpoints } from '@/api/endpoints'

const balance = endpoints.use('balance')

export default {
  getBalanceValidation: ({ commit }, payload) => new Promise((resolve, reject) => {
    balance.getBalanceValidation(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  })
}
