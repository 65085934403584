<template>
  <div class="ph-container">
    <v-container fluid>
      <v-breadcrumbs
      :items="items"
      >
      </v-breadcrumbs>
      <v-col :cols="7">
        <v-card class="ml-5 rounded-lg" outlined elevation="2">
          <v-row class="md-12 ma-5" no-gutters>
            <v-col :cols="6">
              <h4 class="custom-heading ml-4 pb-4 pt-2 pb-md-6 primary--text font-weight-black">Branch</h4>
            </v-col>
            <v-col :cols="6">
              <h4 class="custom-heading ml-4 pb-4 pt-2 pb-md-6 primary--text font-weight-black">Action</h4>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-row class="md-12 ma-5" no-gutters>
            <v-col :cols="6">
              <v-autocomplete
              outlined
              class="pr-md-2 pb-3 pb-md-8 ph-textbox"
              @change="performResetAction"
              :items="branches"
              item-value="value"
              item-text="label">
              </v-autocomplete>
            </v-col>
            <v-col :cols="6">
              <v-radio-group
              inline
              v-model="resetAction"
              class="ml-3"
              row
              >
                <v-radio label="Reset" value="GETRESET"></v-radio>
                <v-radio label="Backdate" value="BACKDATE"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { renderToast } from '@/utils'

export default {
  async mounted () {
    await this.$store.dispatch('manualReceipt/getBranchList')
  },
  data () {
    return {
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Admin',
          disabled: false,
          href: '#'
        },
        {
          text: 'Reset Date',
          disabled: false,
          href: '#'
        }
      ],
      resetAction: 'GETRESET'
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      branchList: 'manualReceipt/branchList'
    }),
    branches () {
      return this.branchList.map(branch => ({
        label: branch.branch,
        value: branch.location_id
      }))
    }
  },
  methods: {
    async performResetAction () {
      const payload = {
        location_id: this.currUser.location_id,
        action_type: this.resetAction
      }
      if (this.resetAction === 'GETRESET') {
        const res = await this.$store.dispatch('resetDate/getResetDate', payload)
        if (res.code === '200') {
          const resetPayload = {
            location_id: this.currUser.location_id,
            action_type: 'RESET'
          }
          const response = await this.$store.dispatch('resetDate/resetDate', resetPayload)

          if (response.code === '200') {
            renderToast('success', 'Success', 'Location reset successful. Please Login again')
          } else {
            renderToast('error', 'Error', 'Set Location Failed')
          }
        } else {
          renderToast('error', 'Failed', 'Latest transaction date has already been set')
        }
      } else {
        const res = await this.$store.dispatch('resetDate/getResetDate', payload)
        if (res.code === 200) {
          renderToast('success', 'Success', res.message)
        } else {
          renderToast('error', 'Error', res.message)
        }
      }
    }
  }
}
</script>
