<template>
  <div v-if="displayCustomerSearch" ref="receiveCustomers">
    <v-card class="ph-nonex-card">
      <v-card-title>
        <h1 color="primary" class="ph-nonex-card-title">Customer Search</h1>
      </v-card-title>
      <v-card-text class="ph-nonex-search-container">
        <div v-if="searchData.results !== null && manualSearch === false">
          <p><b>Customer not here? Try</b></p>
          <div class="ph-nonex-search-buttons">
            <v-btn
              color="primary"
              elevation="0"
              class="ph-primary-button ph-primary-execute-btn"
              @click="openCustomerSearch"
            >
              Manual Customer Search
            </v-btn>
            <div class="ph-nonex-search-divider">
              <p>OR</p>
            </div>
            <v-btn
              color="primary"
              elevation="0"
              class="ph-primary-button ph-primary-execute-btn"
              @click="toNewCustomer"
            >
              Create a new customer
            </v-btn>
          </div>
        </div>
        <v-row no-gutters v-if="manualSearch === true">
          <v-col :cols="12" :sm="5" class="pt-2 px-2 ph-textbox-container">
            <v-text-field
              v-model="firstName"
              class="ph-textbox"
              placeholder="First Name"
              :rules="requiredRules"
              @input="convertToProperCase('firstName')"
              hide-details
              autofocus
              outlined
            />
          </v-col>
          <v-col :cols="12" :sm="5" class="pt-2 px-2 ph-textbox-container">
            <v-text-field
              v-model="lastName"
              class="ph-textbox"
              placeholder="Last Name"
              :rules="requiredRules"
              @input="convertToProperCase('lastName')"
              hide-details
              outlined
            />
          </v-col>
          <v-col :cols="12" :sm="2" class="pt-2 px-2 ph-textbox-container">
            <v-btn
              @click="doSearch"
              class="rounded-lg"
              color="primary"
              :disabled="!firstName || !lastName"
              x-large
              block
            >
              <v-icon class="mr-1">mdi-magnify</v-icon>
              <span class="d-inline d-sm-none d-md-inline">Search</span>
            </v-btn>
          </v-col>
        </v-row>
        <br>
        <v-divider></v-divider>
        <br>
        <search-result v-if="searchData.results !== null && showResult" v-bind:isNotCustomerPage="isNotCustomerPage"/>
        <br>
        <div v-if="!isCustomerExist">
          <h1><b>No Record Found</b></h1>
          <br>
          <p>Looks like the customer you are looking for doesn't exist. Kindly create a new record for the customer here</p>
          <v-btn
            color="primary"
            elevation="0"
            class="ph-primary-button ph-primary-execute-btn"
            :to="{ path: '/customers/new', query: { persistData: true, redirectURL: this.$route.path } }"
          >
            Create new Customer
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { trim, toProperCase, renderToast } from '@/utils'
import SearchResult from '@/components/customers/SearchResult.vue'

export default {
  name: 'ReceiveMoneyCustomerSearch',
  data () {
    return {
      searchResults: [],
      isNotCustomerPage: true,
      showResult: false,
      displayCustomerSearch: false,
      manualSearch: false,
      isCustomerExist: true,
      firstName: '',
      lastName: '',
      requiredRules: [
        v => !!v || 'This field is required'
      ]
    }
  },
  components: {
    SearchResult
  },
  computed: {
    ...mapGetters({
      searchData: 'customers/searchData',
      currUser: 'auth/currUser',
      currentTrx: 'nonex/currentTrx'
    })
  },
  watch: {
    searchData: {
      handler (getResult) {
        this.searchResults = getResult

        if (getResult) {
          this.displayCustomerSearch = true
          const that = this

          setTimeout(function () {
            that.$nextTick(() => {
              that.$refs.receiveCustomers.scrollIntoView({ behavior: 'smooth' })
            })
          }, 500)
        } else {
          this.displayCustomerSearch = false
        }

        if (getResult.results !== null) {
          this.showResult = true
          this.displayCustomerSearch = true
        } else {
          this.showResult = false
          this.displayCustomerSearch = false
        }
      },
      deep: true
    }
  },
  methods: {
    doSearch () {
      this.isCustomerExist = true

      if (this.firstName && this.lastName) {
        const firstName = this.firstName.replace(/(^\s+|\s+$)/g, '')
        const lastName = this.lastName.replace(/(^\s+|\s+$)/g, '')
        const keyword = `${lastName},${firstName}`

        this.searchData.results = []
        this.$store.dispatch('customers/doSearch', keyword).then(res => {
          if (res.result.length > 0) {
            this.isCustomerExist = true
          } else {
            this.isCustomerExist = false
          }
        })
      } else {
        this.searchData.results = []
      }
    },
    hideResults () {
      this.searchData.results = null
      this.isCustomerExist = true
    },
    convertToProperCase (field) {
      this[field] = toProperCase(trim(this[field]))
    },
    async openCustomerSearch () {
      this.manualSearch = true
    },
    // additional balance validation for FLA
    async toNewCustomer () {
      const res = await this.$store.dispatch('balance/getBalanceValidation', {
        location_id: this.currUser.location_id,
        trx_date: this.currUser.trx_date,
        user_id: this.currUser.user_id,
        trx_amount: this.currentTrx.result.principal_amount
      })

      if (res.code === 200) {
        renderToast('success', 'Balance Validation Success', res.message)
        const path = '/customers/new'
        const query = { persistData: true, redirectURL: this.$route.path }

        this.$router.push({ path, query })
      } else {
        renderToast('error', 'Balance Validation Error', 'The user has insufficient balance to proceed with this transaction. The page will now refresh shortly')
        setTimeout(function () {
          window.location.href = '/pera-remit/receive'
        }, 5000)
      }
    }
  }
}
</script>
<style scoped>
  .ph-nonex-card-title {
    font-family: 'Baloo Extra Bold' , sans-serif;
    font-size: 28px;
    color: #1A2791;
  }

  .ph-primary-button {
    background: #FFF;
    color: #1A2791;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
  }

  .ph-nonex-search-buttons {
    display: flex;
    flex-direction: column;
  }

  .ph-nonex-search-divider {
    margin: 5px 15px;
    text-align: center;
  }

  .ph-nonex-search-divider p {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media only screen and (min-width: 600px) {
    .ph-nonex-search-buttons {
      flex-direction: row;
    }
  }
</style>
