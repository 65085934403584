import { endpoints } from '@/api/endpoints'

const resetDate = endpoints.use('resetDate')

export default {
  getResetDate: ({ commit }, payload) => new Promise((resolve, reject) => {
    resetDate.getResetDate(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  resetDate: ({ commit }, payload) => new Promise((resolve, reject) => {
    resetDate.resetDate(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  })
}
