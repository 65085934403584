import apigee from '@/api/apigee'
import api from '@/api'

export default {
  getAdjRequestList (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/nonex/grid-adj', payload)
  },
  // request list for WU
  getAdjWURequestList (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/grid-wu-adjustment', payload)
  },
  searchControlNumber (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/nonex/search', payload)
  },
  confirmAdjustmentRequest (payload) {
    return apigee.put(`/uspdata/v1/usp-maintenance/nonex/update/${payload.id}`, payload.data)
  },
  getAdjApprovalList (userId) {
    return apigee.get(`/uspdata/v1/usp-transaction/maintenance/adjustment/nonex?user_id=${userId}`)
  },
  // approval list for WU
  getAdjWUApprovalList (payload) {
    return apigee.get('/uspdata/v1/usp-maintenance/get-wu-adjustment')
  },
  customerCSLookup (controlNo) {
    return apigee.get(`/uspdata/v1/usp-transaction/maintenance/adjustment/trx/search?control_number=${controlNo}`)
  },
  // for wu customer lookup
  customerWUCSLookup (controlNo) {
    return apigee.get(`/uspdata/v1/usp-maintenance/search-wu-transaction?wu_mtcn=${controlNo}`)
  },
  unpayUnitellerTrx (payload) {
    return apigee.post('/uspdata/v1/usp-transaction/uniteller/reversal', payload)
  },
  unpayRemitlyTrx (payload) {
    return apigee.post('/uspdata/v1/usp-transaction/remitly/reversal', payload)
  },
  amendAyannahTrx (payload) {
    return apigee.post('/uspdata/v1/usp-transaction/ayannah/amend', payload)
  },
  cancelAyannahTrx (payload) {
    return api.post('/v1/remit/nonex/ayannah/cancel', payload)
  },
  updateAdjustmentTrx (payload) {
    return apigee.put(`/uspdata/v1/usp-transaction/maintenance/adjustment/nonex/${payload.id}`, payload.data)
  },
  // wu request search
  wuAdjustmentSearch (controlNo) {
    return apigee.get(`/uspdata/v1/usp-maintenance/search-wu-transaction?wu_mtcn=${controlNo}`)
  },
  // wu request
  wuSubmitRequest (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/insert-wu-adjustment', payload)
  },
  // ayannah real time request
  cancelAyannahTrxRT (payload) {
    return api.post('/v1/remit/nonex/ayannah/cancel', payload)
  }
}
