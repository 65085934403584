export default {
  SET_ADJ_APPROVAL_LIST (state, payload) {
    state.adjApprovalList = payload
  },
  SET_ADJ_REQUEST_LIST (state, payload) {
    state.adjRequestList = payload
  },
  SET_ADJ_REQUEST_WU_LIST (state, payload) {
    payload.data.forEach(item => {
      item.control_number = item.wu_mtcn
      item.sender_name = item.wu_sender_name
      item.receiver_name = item.wu_payee_name
      item.dst_amount = item.dst ? item.dst : 0
      item.service_charge = item.wu_total_charge
      item.remco_id = 'Western'
    })
    state.adjWURequestList = payload
  },
  SET_ADJ_APPROVAL_WU_LIST (state, payload) {
    payload.forEach(item => {
      item.control_number = item.wu_mtcn
      item.remco_id = 'Western'
    })
    state.adjWUApprovalList = payload
  },
  SET_IS_WU_STATE (state, payload) {
    state.isWu = payload
  }
}
