<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
        :items="items"
        divider=">"
      ></v-breadcrumbs>
      <v-row class="ph-transaction-container">
        <v-col cols="12" v-if="showSearchForm">
          <NonexAdjustmentSearch v-bind:adjLookupResult.sync="adjLookupResult" v-bind:adjRequestForm.sync="adjRequestForm" @backButton="backButton" @searchAdjTransaction="searchAdjTransaction" />
        </v-col>
        <v-col cols="12" v-if="showFieldForm">
          <NonexAdjustmentFields v-bind:adjRequestForm.sync="adjRequestForm" @backButton="backButton" @submitAdjFormRequest="submitAdjFormRequest"/>
        </v-col>
      </v-row>
      <v-dialog v-model="resultDialog" persistent :width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '100%' : '60%'">
        <v-card class="rounded-lg">
          <v-card-title class="py-md-6">
            <h3 class="custom-heading primary--text">
              WU Adjustment Search Result
            </h3>
            <v-spacer />
            <v-btn @click="resultDialog = false" small icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <p><b>Search results for control number: {{adjRequestForm.control_number}}</b></p>
            <v-row align="center" no-gutters>
              <v-col :cols="12" :md="7">
                <p>Select a Transaction</p>
              </v-col>
            </v-row>
            <v-data-table
              :headers="headers"
              class="mt-md-6 ph-clickable-table"
              :items="adjLookupResult"
              :items-per-page="5"
              no-data-text="No transactions found"
              @click:row="selectTransaction"
            >
              <!-- :headers="headers" -->
              <!-- <template v-slot:[`item.name`]="{ item }">
                <v-avatar class="mr-1" size="25px" :style="avatarColor(item.full_name)">
                  <span class="white--text text-body-2">{{ avatarInitial(item.full_name) }}</span>
                </v-avatar>
                {{ toProperCase(item.first_name) }} {{ toProperCase(item.last_name) }}
              </template>
              <template v-slot:[`item.gender`]="{ item }">
                {{ toProperCase(item.gender) || '-' }}
              </template>
              <template v-slot:[`item.mobile_no`]="{ item }">
                {{ item.mobile_no || '-' }}
              </template> -->
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="adjDetailDialog" width="600" persistent>
        <v-card>
          <v-card-title class="ph-dialog-title">
            Transaction Details
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <br>
            <div class="ph-confirm-subtitle">
              <p><b>Kindly check all the details before creating a request.</b></p>
            </div>
            <br>
            <table class="ph-payout-summary" v-if="Object.keys(adjSelectedTrx).length !== 0 && adjDetailDialog">
              <tr>
                <td><strong>Transaction Details</strong></td>
              </tr>
              <tr>
                <td>Control Number</td>
                <td>{{ adjSelectedTrx.control_number }}</td>
              </tr>
              <tr>
                <td>Currency</td>
                <td>PHP</td>
              </tr>
              <tr>
                <td>Remittance Company</td>
                <td>Western Union</td>
              </tr>
              <tr>
                <td>Service Charge</td>
                <td>{{ adjSelectedTrx.wu_total_charge }}</td>
              </tr>
              <tr>
                <td>Total Amount</td>
                <td>{{ adjSelectedTrx.net_amount }}</td>
              </tr>
              <br>
              <tr>
                <td><strong>Sender Details</strong></td>
              </tr>
              <tr>
                <td>Full Name</td>
                <td>{{ adjSelectedTrx.wu_sender_name }}</td>
              </tr>
              <tr>
                <td>Birth Date</td>
                <td>{{ adjSelectedTrx.birth_date }}</td>
              </tr>
              <tr>
                <td>ID Type</td>
                <td>{{ adjSelectedTrx.id_type }}</td>
              </tr>
              <tr>
                <td>ID Number</td>
                <td>{{ adjSelectedTrx.id_number }}</td>
              </tr>
              <tr>
                <td>Present Address</td>
                <td>
                  {{ `${adjSelectedTrx.curr_address},
                  ${adjSelectedTrx.curr_barangay},
                  ${adjSelectedTrx.curr_city},
                  ${adjSelectedTrx.curr_province},
                  ${adjSelectedTrx.curr_country},` }}
                </td>
              </tr>
              <br>
              <tr>
                <td><strong>Receiver Details</strong></td>
              </tr>
              <tr>
                <td>Full Name</td>
                <td>{{ `${adjSelectedTrx.recipient_first_name}, ${adjSelectedTrx.recipient_middle_name}, ${adjSelectedTrx.recipient_last_name}` }}</td>
              </tr>
            </table>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="danger"
              text
              class="ph-action-button"
              @click="adjDetailDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              class="ph-action-button"
              elevation="0"
              @click="confirmSelection"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import NonexAdjustmentSearch from '@/components/nonexAdjustment/NonexAdjustmentSearch.vue'
import NonexAdjustmentFields from '@/components/nonexAdjustment/NonexAdjustmentFields.vue'
// import { format } from 'date-fns'
import { mapGetters } from 'vuex'
import { renderToast } from '@/utils'

export default {
  name: 'NonexAdjustmentForm',
  components: {
    NonexAdjustmentSearch,
    NonexAdjustmentFields
  },
  data () {
    return {
      showSearchForm: true,
      showFieldForm: false,
      headers: [
        {
          text: 'Control number',
          sortable: true,
          value: 'wu_mtcn'
        },
        {
          text: 'Receiver Name',
          sortable: true,
          value: 'wu_payee_name'
        },
        {
          text: 'Principal Amount',
          sortable: true,
          value: 'principal_amount'
        },
        {
          text: 'Service Fee',
          sortable: true,
          value: 'wu_total_charge'
        },
        {
          text: 'Total Amount',
          sortable: true,
          value: 'net_amount'
        }
      ],
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Pera Remit',
          disabled: false,
          href: '/pera-remit'
        },
        {
          text: 'Nonex Adjustment',
          disabled: false,
          href: '/pera-remit/nonex-adjustment'
        },
        {
          text: 'Request WU Form',
          disabled: true,
          href: '#'
        }
      ],
      adjRequestForm: {},
      adjLookupResult: [],
      adjSelectedTrx: {},
      resultDialog: false,
      adjDetailDialog: false
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  methods: {
    renderToast,
    backButton (type) {
      this.adjRequestForm = {}

      if (type === 'showSearchForm') {
        this.showSearchForm = true
        this.showFieldForm = false
      } else {
        this.$router.push('/pera-remit/nonex-adjustment')
      }
    },
    searchAdjTransaction () {
      const payload = {
        control_number: this.adjRequestForm.control_number
      }

      let adjCategory = ''

      switch (this.adjRequestForm.type) {
        case 'amend':
          adjCategory = 'amend'
          break
        case 'cancel':
          adjCategory = 'cancel'
          break
        case 'refund':
          adjCategory = 'refund'
          break
        case 'unpay':
          adjCategory = 'unpay'
          break
      }

      this.$store.dispatch('nonexAdjustment/wuAdjustmentSearch', payload.control_number).then(res => {
        let tranType = ''

        switch (adjCategory) {
          case 'amend':
          case 'cancel':
          case 'refund':
            tranType = 'SO'
            break
          case 'unpay':
            tranType = 'PO'
        }
        this.adjLookupResult = res?.result.filter(item => item.wu_tran_type === tranType)

        if (res.code >= 200 && res.code <= 299) {
          if (this.adjLookupResult.length === 1) {
            this.selectTransaction(this.adjLookupResult[0])
          } else {
            this.resultDialog = true
          }
        } else {
          this.renderToast('error', 'Lookup Error', res.message)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    submitAdjFormRequest () {
      console.log('adjRequestForm', this.adjRequestForm)
      console.log('adjSelectedTrx', this.adjSelectedTrx)
      const payload = {
        location_id: this.currUser.location_id,
        trx_date: this.adjSelectedTrx.wu_trx_date,
        trx_id: this.adjSelectedTrx.trx_wu_id,
        service_code: 'PERAREMIT',
        adj_category: this.adjRequestForm.type,
        adj_requested_by: this.currUser.user_id,
        adj_requested_date: this.currUser.trx_date,
        adj_disposition: this.adjRequestForm.disposition,
        adj_requested_reason: this.adjRequestForm.reason,
        adj_non_conforming: this.adjRequestForm.nonConformingParty,
        adj_status: 1,
        adj_requested_remarks: this.adjRequestForm.reason,
        branch: this.currUser.location_name,
        wu_mtcn: this.adjSelectedTrx.wu_mtcn,
        requester: `${this.currUser.first_name} ${this.currUser.last_name}`
      }

      if (this.adjRequestForm.type === 'amend') {
        payload.adj_first_name = this.adjRequestForm.receiverFirstName
        payload.adj_middle_name = this.adjRequestForm.receiverMiddleName
        payload.adj_last_name = this.adjRequestForm.receiverLastName
      }

      let ifIncomplete = false

      Object.keys(payload).forEach(key => {
        const value = payload[key]

        if (value === '' || value === null || value === undefined) {
          ifIncomplete = true
        } else {
          ifIncomplete = false
        }
      })

      if (ifIncomplete) {
        this.renderToast('error', 'Request Error', 'The fields are incomplete. Please fill-out the missing fields.')
      } else {
        this.$store.dispatch('nonexAdjustment/wuSubmitRequest', payload).then(res => {
          if (res.code >= 200 && res.code <= 299) {
            this.showSearchForm = false
            this.showFieldForm = false
            this.renderToast('success', 'Request Successful', 'The entry was successfully submitted.')
            this.$router.push('/pera-remit/nonex-adjustment')
          } else {
            this.renderToast('error', 'Request Error', res.message)
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    selectTransaction (item) {
      this.adjSelectedTrx = item
      this.adjRequestForm.id = item.id
      this.adjDetailDialog = true
    },
    confirmSelection () {
      this.showSearchForm = false
      this.resultDialog = false
      this.adjDetailDialog = false
      this.showFieldForm = true
    }
  },
  beforeMount () {
    this.$store.commit('nonexAdjustment/SET_IS_WU_STATE', true)
  }
}
</script>
<style scoped>
  .ph-payout-summary {
    width: 100%
  }

  .ph-payout-summary tr td:first-child {
    text-align: left !important
  }

  .ph-payout-summary tr td:last-child {
    text-align: right
  }

  .ph-action-button-receive-confirm {
    padding: 25px !important
  }
</style>
